<template>
  <div>
    <profile-setting />
  </div>
</template>

<script>
import ProfileSetting from '@/components/profileSetting/ProfileSetting.vue';
import store from '@/store/index';
export default {
  components: {
    ProfileSetting,
  },

  data() {
    return {
      updateProfileData: null,
    };
  },

  created() {
    // this.updateProfile();
  },

  methods: {
    updateProfile() {
      store.dispatch('app-customer/getProfile', 1234567890).then((response) => {
        this.updateProfileData = response;
      });

      store.dispatch('app-dashboard/fetchSubscribeDashboard').then(() => {});
      console.log('updateProfileData:', this.updateProfileData);
      console.log('view call update profile');
    },
  },
};
</script>

<style></style>
