<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>

      <general-setting />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <password-setting />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <!-- <b-tab>
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Information</span>
      </template>

      <information-setting />
    </b-tab> -->

    <!-- social links -->
    <!-- <b-tab>
     
      <template #title>
        <feather-icon icon="LinkIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Social</span>
      </template>

      <social-setting />
    </b-tab> -->

    <!-- notification -->
    <!-- <b-tab>
     
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <notification-setting />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import GeneralSetting from '@/components/profileSetting/GeneralSetting.vue';
import PasswordSetting from '@/components/profileSetting/PasswordSetting.vue';
import InformationSetting from '@/components/profileSetting/InformationSetting.vue';
import SocialSetting from '@/components/profileSetting/SocialSetting.vue';
import NotificationSetting from '@/components/profileSetting/NotificationSetting.vue';

export default {
  components: {
    BTabs,
    BTab,
    GeneralSetting,
    PasswordSetting,
    InformationSetting,
    SocialSetting,
    NotificationSetting,
  },
  data() {
    return {};
  },
  beforeCreate() {},
};
</script>
