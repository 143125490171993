<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img rounded :src="generalSetting.profile_pic" height="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->
        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="resetImage"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input
              v-model="generalSetting.user_login"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Name" label-for="account-name">
            <b-form-input
              v-model="generalSetting.name"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input
              v-model="generalSetting.email"
              name="email"
              placeholder="Email"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Company" label-for="account-company">
            <b-form-input
              v-model="generalSetting.company_name"
              name="company"
              placeholder="Company name"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateGeneralSetting"
          >
            Save changes
          </b-button>
         <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
             type="reset"
            class="mt-2"
            @click.prevent="resetForm()"
          >
            Reset
          </b-button> 
          <!--  <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import store from '@/store/index';
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      /*  generalSetting: {
        avatar: require("@/assets/images/portrait/small/avatar-s-11.jpg"),
        username: "johndoe",
        fullName: "John Doe",
        email: "granger007@hogward.com",
        company: "Mall91",
      }, */
      /* generalSetting: {},
      profileFile: null, */
    };
  },
  created() {},
  mounted() {
    //this.getGeneralSetting();
  },

  methods: {
   /*  getGeneralSetting() {
      store.dispatch('app-customer/getProfile').then((response) => {
        this.generalSetting = response.data.data;
      });
      store.dispatch('app-dashboard/fetchSubscribeDashboard').then(() => {});
    },
    updateGeneralSetting() {
      store
        .dispatch('app-customer/updateGeneralSetting', this.generalSetting)
        .then((response) => {
          if (response.data.result.code === 200) {
            this.$bvToast.toast('Profile updated', {
              title: 'Success',
              variant: 'success',
              solid: true,
            });
          } else {
            this.$bvToast.toast('Something went wrong', {
              title: 'Failed',
              variant: 'danger',
              solid: true,
            });
          }
        })
        .catch((error) => {
          this.$bvToast.toast('Something went wrong', {
            title: 'Failed',
            variant: 'danger',
            solid: true,
          });
        });
    }, */
    //resetForm() {},
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const generalSetting= ref({});
    const profileFile= ref(null)

    //const { inputImageRenderer } = useInputImageRenderer(previewEl, refInputEl);
     const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      //props.userData.avatar = base64
      generalSetting.value.profile_pic = base64
    })
    function getGeneralSetting(){
      store.dispatch('app-customer/getProfile').then((response) => {
      generalSetting.value = response.data.data;
      });
      store.dispatch('app-dashboard/fetchSubscribeDashboard').then(() => {});
    }
    function  updateGeneralSetting(){
      store
        .dispatch('app-customer/updateGeneralSetting', this.generalSetting)
        .then((response) => {
          if (response.data.result.code === 200) {
            this.$bvToast.toast('Profile updated', {
              title: 'Success',
              variant: 'success',
              solid: true,
            });
          } else {
            this.$bvToast.toast('Something went wrong', {
              title: 'Failed',
              variant: 'danger',
              solid: true,
            });
          }
        })
        .catch((error) => {
          this.$bvToast.toast('Something went wrong', {
            title: 'Failed',
            variant: 'danger',
            solid: true,
          });
        });
      onMounted(() => {
      generalSetting()
    })
    }
    function resetForm(){
      generalSetting.value.company_name = '',
      generalSetting.value.name = '',
      generalSetting.value.user_login = ''
      //generalSetting.value.company_name = '',
    }
    function resetImage(){
      generalSetting.value.profile_pic = ''
    }
    //getGeneralSetting()
    onMounted(() => {
      getGeneralSetting();
    })
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      getGeneralSetting,
      updateGeneralSetting,
      resetForm,
      resetImage,
      generalSetting,
      profileFile
    };
  },
};
</script>
